import "./Home.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWandSparkles } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  return (
    <div className="home">
      <div className="content">
        <div className="header-div">
          <h1>
            <span className="plus-jakarta-sans-500">
              Effortlessly estimate story points and simplify your planning
              process with&nbsp;
            </span>
            <span className="plus-jakarta-sans-700 primary">
              Scrum Wizardry
            </span>
          </h1>
        </div>
        <p className="header-content">
          Welcome to&nbsp;
          <span className="plus-jakarta-sans-800">Scrum Wizardry</span>, your
          ultimate platform for mastering Scrum with ease and precision. Whether
          you’re a seasoned professional or just beginning your Agile journey,
          our platform is here to revolutionize the way you manage Scrum tasks.
        </p>

        <p className="header-content">
          Our platform makes planning poker simple and efficient for your team.
          The seamless integration of agile principles and magical elements
          enhances team collaboration, streamlines planning processes, and
          fosters accurate estimations, ultimately enabling teams to achieve
          their project objectives with agility and precision.
        </p>

        <div className="how-works">
          <h2 className="plus-jakarta-sans-700 h3">
            Why Choose Scrum Wizardry?
          </h2>

          <p className="plus-jakarta-sans-300 h4">
            Scrum Wizardry is designed to tackle common challenges in Agile
            workflows. Our platform combines intuitive tools with smart features
            to make planning poker seamless and enjoyable. Here's what sets us
            apart:
          </p>

          <div className="steps">
            <div>
              <p className="sub-header plus-jakarta-sans-500">
                <FontAwesomeIcon
                  icon={faWandSparkles}
                  style={{
                    color: "#0171C2",
                    marginRight: "5px",
                    fontSize: "12px",
                  }}
                />
                Simplified Agile Processes:
              </p>
              <p>
                Streamline your planning and collaboration with tools that fit
                teams of all sizes, from startups to enterprises.
              </p>
            </div>
            <div>
              <p className="sub-header plus-jakarta-sans-500">
                <FontAwesomeIcon
                  icon={faWandSparkles}
                  style={{
                    color: "#0171C2",
                    marginRight: "5px",
                    fontSize: "12px",
                  }}
                />
                Accurate Estimations:
              </p>
              <p>
                Use our platform to align your team on story points, enhancing
                clarity and decision-making.
              </p>
            </div>
            <div>
              <p className="sub-header plus-jakarta-sans-500">
                <FontAwesomeIcon
                  icon={faWandSparkles}
                  style={{
                    color: "#0171C2",
                    marginRight: "5px",
                    fontSize: "12px",
                  }}
                />
                Enhanced Collaboration:
              </p>
              <p>
                Share progress, communicate easily, and work together
                efficiently to achieve your goals.
              </p>
            </div>
            <div>
              <p className="sub-header plus-jakarta-sans-500">
                <FontAwesomeIcon
                  icon={faWandSparkles}
                  style={{
                    color: "#0171C2",
                    marginRight: "5px",
                    fontSize: "12px",
                  }}
                />
                Scalable Solutions:
              </p>
              <p>
                Our platform grows with your team, providing the flexibility to
                adapt to your unique needs.
              </p>
            </div>
          </div>
        </div>

        {/* <p>
          Our platform is like your trusty sidekick for all things Scrum. From planning sprints to those daily stand-ups, we've got your back. No more juggling spreadsheets or drowning in email chains. With our user-friendly interface and powerful features, you'll breeze through your Scrum workflow.
        </p> */}

        {/* <p>
          Whether you're a tiny startup or a big player, our platform grows with
          you. Collaborate effortlessly with your team, keep tabs on progress in
          real-time, and glean insights to keep improving. Our mission is
          simple: to help you focus on what truly matters—delighting your
          customers.
        </p>

        <p>
          Join the wave of teams worldwide who've embraced our platform and
          discovered the joy of hassle-free Scrum management. Welcome to the
          future of Agile collaboration. Welcome to our platform!
        </p> */}

        <div className="how-works">
          <h2 className="plus-jakarta-sans-700 h3">How It Works</h2>
          {/* <motion.div
                initial={{
                  x: "70%",
                  opacity: 0,
                }}
                animate={{
                  x: "0",
                  opacity: 1,
                }}
                transition={{ duration: 1 }}
                style={{ width: '100%' }}
              >
                <div style={{ position: 'relative', paddingBottom: 'calc(51.05820105820106% + 41px)', height: 0, width: '100%;'}}>
                  <iframe
                    src="https://demo.arcade.software/o5DduILxXtVzuYx0DDxb?embed&show_copy_link=true"
                    title="Scrum Wizardy"
                    loading="lazy"
                    allow="clipboard-write"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', colorScheme: 'light', border: 0 }} />
                </div>
              </motion.div> */}
          <p className="plus-jakarta-sans-300 h4">
            Follow these easy steps to start estimating your story points:
          </p>
          <div className="steps">
            <div>
              <p className="sub-header plus-jakarta-sans-500">
                <FontAwesomeIcon
                  icon={faWandSparkles}
                  style={{
                    color: "#0171C2",
                    marginRight: "5px",
                    fontSize: "12px",
                  }}
                />
                Create a Battlefield:
              </p>
              <p>
                Invoke the "Create a Battlefield" spell to commence your
                session. A unique, enchanted link to the room will be generated
                for you.
              </p>
            </div>
            <div>
              <p className="sub-header plus-jakarta-sans-500">
                <FontAwesomeIcon
                  icon={faWandSparkles}
                  style={{
                    color: "#0171C2",
                    marginRight: "5px",
                    fontSize: "12px",
                  }}
                />
                Share the Link:
              </p>
              <p>
                Share this magical link with your team of wizards via email,
                chat, or other communication channels. Team members join by
                clicking the enchanted link.
              </p>
            </div>
            <div>
              <p className="sub-header plus-jakarta-sans-500">
                <FontAwesomeIcon
                  icon={faWandSparkles}
                  style={{
                    color: "#0171C2",
                    marginRight: "5px",
                    fontSize: "12px",
                  }}
                />
                Select Your Cards:
              </p>
              <p>
                Within the room, each participant will have access to a deck of
                planning poker cards. Team members select their estimates for
                the given user story or task, as if choosing the perfect spell.
              </p>
            </div>
            <div>
              <p className="sub-header plus-jakarta-sans-500">
                <FontAwesomeIcon
                  icon={faWandSparkles}
                  style={{
                    color: "#0171C2",
                    marginRight: "5px",
                    fontSize: "12px",
                  }}
                />
                Reveal the Estimates:
              </p>
              <p>
                Once all wizards have selected their cards, the room owner can
                reveal the estimates like lifting a magical veil. This promotes
                a discussion to align on a final estimate, ensuring the magic of
                consensus is achieved.
              </p>
            </div>
            <div>
              <p className="sub-header plus-jakarta-sans-500">
                <FontAwesomeIcon
                  icon={faWandSparkles}
                  style={{
                    color: "#0171C2",
                    marginRight: "5px",
                    fontSize: "12px",
                  }}
                />
                Delete Estimates:
              </p>
              <p>
                After successfully completing a story point, Scrum Wizardy makes
                it easy to prepare for the next task by allowing you to delete
                the previous estimates. This ensures a clean slate for the new
                story. Use the "Delete Estimates" option to remove all the old
                estimates, enabling the team to focus on the upcoming story with
                clarity and precision. By resetting the estimates, Scrum Wizardy
                helps maintain an organized workflow, allowing the team to
                seamlessly transition from one task to the next without any
                leftover data cluttering the planning process.
              </p>
            </div>
          </div>
        </div>

        <div className="how-works">
          <h2 className="plus-jakarta-sans-700 h3">Why Planning Poker?</h2>
          <p className="plus-jakarta-sans-300 h4">
            Planning poker is a tried-and-true method for Agile teams to
            estimate project complexity while fostering open discussions and
            collaboration. Scrum Wizardry takes this method to the next level by
            offering a digital platform that makes the process more accessible,
            engaging, and productive.
          </p>
        </div>

        <div className="how-works">
          <h2 className="plus-jakarta-sans-700 h3">
            Join the Agile Revolution
          </h2>
          <p className="plus-jakarta-sans-300 h4">
            Teams worldwide trust Scrum Wizardry to simplify their Scrum
            processes and achieve better results. Start your planning poker
            session today and experience a new level of Agile collaboration.
          </p>
        </div>

        <div className="how-works">
          <h2 className="plus-jakarta-sans-700 h3">Ready to Plan?</h2>
          <p className="footer">
            Start your <a href="/planning-poker">planning poker</a> session with
            Scrum Wizardry today and experience a seamless way to estimate story
            points with your team!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
